<template>
  <div>
    <el-table
      :header-cell-style="{ background: '#f4f4f4' }"
      :data="dataSource"
      style="width: 100%"
    >
      <el-table-column
        align="center"
        prop="changeTime"
        label="提现时间"
        width="200"
      >
        <template slot-scope="scope">
          {{ getTime(scope.row.changeTime) }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="money" label="金额">
      </el-table-column>
      <el-table-column
        align="center"
        prop="auditStatus"
        label="审核状态"
        v-if="isShow"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.auditStatus == 'D'" style="color: blue">
            审核中
          </span>
          <span v-else-if="scope.row.auditStatus == 'Y'" style="color: green"
            >审核通过</span
          >
          <span v-else-if="scope.row.auditStatus == 'N'" style="color: red"
            >审核不通过</span
          >
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <div
            style="display: flex; justify-content: center"
            v-if="scope.row.auditStatus == 'D'"
          >
            <div class="wid50 bg_f4f4 het5"></div>
          </div>
          <div
            style="display: flex; justify-content: center"
            v-else-if="scope.row.auditStatus == 'Y'"
          >
            <span v-if="scope.row.proof == ''" class="co_999"
              >暂未上传凭证</span
            >
            <el-button
              type="text"
              size="mini"
              @click="filePreview(scope.row.proof)"
              v-if="judgeFileType(scope.row.proof) == 'pdf' && scope.row.proof"
              >查看凭证</el-button
            >
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.proof"
              :preview-src-list="[scope.row.proof]"
              v-if="judgeFileType(scope.row.proof) == 'image' && scope.row.proof"
            >
            </el-image>
          </div>
          <div v-else-if="scope.row.auditStatus == 'N'">
            <div class="btn">
              <el-button
                type="primary"
                size="mini"
                plain
                @click="checkReason(scope.row.auditReason)"
                >查看原因</el-button
              >
            </div>
            <el-popconfirm
              title="是否重新提交审核？"
              @confirm="submitAgain(scope.row.id)"
            >
              <div class="btn Mtp5" slot="reference">
                <el-button type="primary" size="mini">重新提交</el-button>
              </div>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="floatR Mtp20">
      <el-pagination
        background
        :total="ipagination.totalCount"
        :page-size="ipagination.size"
        :current-page="ipagination.current"
        @size-change="onSizeChange"
        @current-change="onPageChange"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { findUserDrawMoneyList } from "@/api/user";
import { listMixin } from "@/mixins/listMixins";
import moment from "moment";
import { againSubmitAuditData } from "@/api/product";
import { eventBus } from "@/config/bus";
export default {
  mixins: [listMixin],
  props: {
    auditStatus: {
      type: Number,
      default: 0,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      extraParam: {
        userId: "",
        status: 0,
      },
      url: {
        list: findUserDrawMoneyList,
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.extraParam.status = this.auditStatus;
    this.extraParam.userId = this.userInfo.id;
    this.loadData();
    eventBus.$off("searchInfo");
    eventBus.$on("searchInfo", (res) => {
      this.extraParam.key = res;
      this.loadData();
    });
  },
  methods: {
    getTime(value) {
      return moment.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
    checkProof(proof) {
      if (this.judgeFileType(proof) == "pdf") {
        window.open(proof, "_blank");
      } else {
        window.open(proof, "_blank");
      }
    },
    // 重新提交
    submitAgain(id) {
      againSubmitAuditData({
        dataId: id,
      }).then((res) => {
        if (res.success) {
          this.loadData();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查看事件
    checkReason(reason) {
      this.$alert(reason, "审核理由", {
        confirmButtonText: "关闭",
      });
    },
    // 校验文件格式
    judgeFileType(fileName) {
      // 后缀获取
      var suffix = "";
      var result = "";
      try {
        var flieArr = fileName.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "image";
        return result;
      }
      // 匹配PDF
      var pdflist = ["pdf"];
      result = pdflist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "pdf";
        return result;
      }
    },
    // 文件预览
    filePreview(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.el-button {
  padding: 3px 10px !important;
}
</style>
